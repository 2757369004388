/* Table of Contents
/* ------------------------------------------------------------

This is a development CSS file which is built to a minified
production stylesheet in assets/built/screen.css

12. Pagination

*/

/* 12. Pagination - Tools to let you flick between pages
/* ---------------------------------------------------------- */

/* The main wrapper for our pagination links */
.pagination {
    position: relative;
    width: 80%;
    max-width: 800px;
    margin: 4rem auto;
    font-family: "Open Sans", sans-serif;
    font-size: 1.3rem;
    color: #9EABB3;
    text-align: center;
}

.pagination a {
    color: #809f44;
    transition: all 0.2s ease;
}

/* Push the previous/next links out to the left/right */
.older-posts,
.newer-posts {
    position: absolute;
    display: inline-block;
    padding: 0 15px;
    border: #bfc8cd 1px solid;
    text-decoration: none;
    border-radius: 4px;
    transition: border 0.3s ease;
}

.older-posts {
    right: 0;
}

.page-number {
    display: inline-block;
    padding: 2px 0;
    min-width: 100px;
}

.newer-posts {
    left: 0;
}

.older-posts:hover,
.newer-posts:hover {
    color: #809f44;
    border-color: #98a0a4;
}

.extra-pagination {
    display: none;
    border-bottom: #EBF2F6 1px solid;
}
.extra-pagination:after {
    display: block;
    content: "";
    width: 7px;
    height: 7px;
    border: #E7EEF2 1px solid;
    position: absolute;
    bottom: -5px;
    left: 50%;
    margin-left: -5px;
    background: #FFF;
    border-radius: 100%;
    box-shadow: #FFF 0 0 0 5px;
}
.extra-pagination .pagination {
    width: auto;
}

/* On page2+ make all the headers smaller */
.paged .main-header {
    max-height: 30vh;
}

/* On page2+ show extra pagination controls at the top of post list */
.paged .extra-pagination {
    display: block;
}

/* Every post, on every page, gets this style on its <article> tag */
.pagination {
    padding-top: 4rem;
    border-top: #bfc8cd 1px solid;
    word-wrap: break-word;
}

/* Add a little circle in the middle of the border-bottom on our .post
   just for the lolz and stylepoints. */
.pagination:before {
    display: block;
    content: "";
    width: 7px;
    height: 7px;
    border: #bfc8cd 1px solid;
    position: absolute;
    top: -5px;
    left: 50%;
    margin-left: -5px;
    background: #f4f8fb;
    border-radius: 100%;
    box-shadow: #f4f8fb 0 0 0 5px;
}

.highlighter-rouge {
    overflow-x: auto;
    max-width: 100%;
    min-width: 100%;
}

figure.highlight,
.highlight pre,
.highlight code,
.highlight table,
.highlight tbody,
.highlight tr,
td.code {
    border: none;
    min-width: 100%;
    max-width: 100%;
}

.highlight table,
.highlight td pre {
    padding: 0;
    margin: 0;
}

.highlight table td {
    border: none;
    margin: none;
    padding: none;
}

.highlight table td:first-child,
.highlight table td:last-child {
    background: none;
}
